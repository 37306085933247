@use "../../../config/variables.scss" as vars;

.digitalTwinScreen-col1 {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid rgb(223, 223, 223);
  color: vars.$primary12;
  font-size: 20px;
}

.digitalTwinScreen-text1 {
  font-size: 16px;
  color: vars.$primary11;
  font-family: vars.$SemiBold;
}

.digitalTwinScreen-text2 {
  font-size: 12px;
  color: gray;
}

.digitalTwinScreen-col3 {
  cursor: pointer;
  color: vars.$primary12;
}

.digitalTwinScreen-image {
  border: 1px solid rgb(215, 215, 215);
  border-radius: 5px;
  padding: 8px;
  img {
    width: 100%;
    border-radius: 5px;
  }
}

.digitalTwinScreen-text3 {
  margin-bottom: 20px;
  font-size: 14px;
  font-family: vars.$Medium;
  color: rgb(31, 31, 31);
}

.digitalTwinScreen-text4 {
  font-size: 20px;
  color: vars.$primary11;
  font-family: vars.$SemiBold;
  margin-bottom: 10px;
}

.digitalTwinScreen-text5 {
  color: gray;
  font-family: vars.$Medium;
  font-size: 14px;
}

.digitalTwinScreen-rowBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.digitalTwinScreen-formBox {
  min-height: 30vh;
  border: 1px solid rgb(215, 215, 215);
  border-radius: 5px;
}

.digitalTwinScreen-col1 {
  cursor: pointer;
}

.digitalTwinScreen-col2 {
  cursor: pointer;
}
.digitalTwinScreen-col3 {
  cursor: pointer;
}
