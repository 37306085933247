@use "../../../config/variables.scss" as vars;

.AuthSideBar {
  padding-top: 10vh;
  padding-bottom: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  color: vars.$primary11;
}

.AuthSideBar-item {
  border-radius: 100%;
  aspect-ratio: 1;
  font-size: 25px;
  color: vars.$primary11;
  padding: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: rgb(243, 243, 243);
    color: vars.$primary12;
  }
  @media only screen and (max-width: 567px) {
    height: 5vh;
  }
}
