//colors
$primary: #ddf3f4;
$primary1: #ddf4e2;
$secondary: #dde8f4;
$secondary1: #e5f1de;
$tertiary: #188a8a;
$tertiary1: #f9da7f;
$tertiary3: #015170;
$quartenery: "#D4D2E8";
$quartenery1: #42d3e1;
$quartenery2: #dafdf5;
$quartenery3: #e3e3e3;
$quartenery4: #e6f1f6;
$black: #000000;
$white: #ffffff;
$button: rgb(8 126 164 / 1);
$button-hover: rgb(8 160 200 / 1);

$primary11: #404756;
$primary12: #087ea4;
$primary13: #ebecf0;

//fonts
$Regular: "Inter-Regular";
$Bold: "Inter-Bold";
$SemiBold: "Inter-SemiBold";
$Medium: "Inter-Medium";
