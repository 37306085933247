@use "../../../config/variables.scss" as vars;
.AuthPageHeader {
  padding: 10px 0px;
  margin: 0px 2px;
  border-bottom: 1px solid rgb(210, 225, 232);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}

.AuthPageHeader-back {
  border: 1px solid rgb(230, 230, 230);
  background-color: #fff;
  border-radius: 5px;
  font-size: 20px;
  padding: 5px 8px;
  cursor: pointer;
  color: vars.$primary11;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: vars.$primary12;
  }
}

.AuthPageHeader-text1 {
  font-size: 18px;
  font-family: vars.$SemiBold;
  color: vars.$primary11;
  margin-bottom: 2px;
}

.AuthPageHeader-text2 {
  font-size: 11px;
  color: rgb(81, 81, 81);
}

.AuthPageHeader-children {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
