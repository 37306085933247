@use "./config/variables.scss" as global;
.screenBox {
  min-height: 120vh;
  padding-top: 5vh;
  padding: 35px;
  @media only screen and (max-width: 647px) {
    padding: 10px;
  }
}
.bg-image {
  display: flex;
  justify-content: center;
  &img {
    width: 100px;
  }
}
.form-container {
  gap: 20px;
  flex-direction: column;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.welcome-txt {
  font-family: global.$Regular;
}

.signInBtn {
  background-color: global.$button;
  font-family: global.$SemiBold;
  &:hover {
    background-color: global.$button-hover;
  }
}

.desc-txt {
  font-family: global.$Regular;
  color: grey;
  font-size: 12px;
}

.logo-txt {
  color: global.$tertiary3;
  font-family: global.$Bold;
  font-size: 25px;
}

.login-Input {
  width: 500px;
  margin-bottom: -25px;
  @media only screen and (min-width: 347px) and (max-width: 606px) {
    width: 300px;
  }
  @media only screen and (max-width: 347px) {
    width: 100px;
  }
}
.serach-Input-container {
  display: flex;
  gap: 17px;
  height: 40px !important;
}

.searchInput {
  width: 300px;
  font-family: global.$Regular;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.sub-header {
  display: flex;
  justify-content: space-between;
  height: 70px;
  align-items: center;
  // margin-bottom: 20px;
}

.sub-header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.sub-head-text {
  font-family: global.$Bold;
  font-size: 16px;
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
}

.addnew-btn {
  background-color: global.$button;
  border-radius: 7px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 20px !important;
  margin: 0;

  &:hover {
    background-color: global.$button-hover;
  }
}

.btnTxt {
  color: global.$white;
  font-size: 14px;
}

// TABLE

.table-container {
  width: 100%;
  font-family: Arial, sans-serif;
}

.table-header {
  background-color: #eaeaea;
  display: flex;
  justify-content: space-between;
  padding: 10px 63px 10px 70px;
  border-radius: 20px 20px 2px 2px;
  @media screen and (max-width: 400px) {
    padding: 10px 6px 10px 15px;
  }
}

.table-header-Txt {
  font-family: global.$Bold;
}

.table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  border: 1.3px solid #e2e2e2;
  border-radius: 8px;
}

.table-cell {
  // flex: 1;
  display: flex;
  align-items: center;
  padding: 10px;
}

.identification-cell {
  flex: 2;
}

.identification {
  display: flex;
  align-items: center;
}

.identification-icon {
  width: 40px;
  height: 40px;
  background-color: #d9d9d9;
  border-radius: 6px;
  margin-right: 10px;
  @media screen and (max-width: 400px) {
    display: none;
  }
}

.identification-details {
  display: flex;
  flex-direction: column;
}

.ship {
  font-family: global.$Bold;
  color: global.$black;
  font-size: 1.0625rem;
}

.description {
  color: global.$black;
  font-size: 0.9em;
  font-family: global.$Regular;
}

.global-asset-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.global-asset-id {
  display: flex;
  align-items: center;
  gap: 30px;
  @media screen and (max-width: 400px) {
    gap: 0px;
  }
}

.arrow-icon-text {
  margin-left: 10px;
  color: global.$black;
  font-family: global.$SemiBold;
  font-size: 18px;
  @media screen and (max-width: 400px) {
    font-size: 14px;
  }
}
