@use "../../../config/variables.scss" as vars;

.subModalScreen-text1 {
  font-size: 16px;
  color: vars.$primary11;
  font-family: vars.$SemiBold;
}

.subModalScreen-text2 {
  font-size: 12px;
  color: gray;
}
