@use "../../../config/variables.scss" as vars;

.dashboard-row {
  display: flex;
  align-items: center;
}

.dashboard-text2 {
  font-size: 25px;
  font-family: vars.$SemiBold;
  color: vars.$primary11;
}
.dashboard-iconBox {
  border: 1px solid rgba(212, 212, 212, 0.383);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  border-radius: 5px;
  background-color: #f8feff30;
}
.dashboard-card1-cardHead {
  padding: 20px;
  border-radius: 10px;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none;
  border: 0.6px solid color;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  &:hover {
    border: 0.1px solid vars.$primary12;
    background-color: #fbfeff;
  }
}

.dashboard-card1-cardBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard-card1-cardBox2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 7px;
}
.dashboard-card1-iconHead {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-card1-title {
  font-family: vars.$SemiBold;
  font-size: 14px;
  color: #000000;
  @media screen and (max-width: 400px) {
    font-size: 11px;
  }
}

.dashboard-card1-descTxt {
  font-family: vars.$Regular;
  font-size: 14px;
  color: #000000;
}
.dashboard-card1-valTxt {
  font-family: vars.$Bold;
  font-size: 14px;
  color: #000000;
}
.dashboard-cardHeadingHead {
  display: flex;
  align-items: center;
  gap: 15px;
}
.dashboard-text1 {
  font-family: vars.$SemiBold;
  color: vars.$primary12;
  font-size: 16px;
}
.dashboard-card3-iconHead {
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-card2-cardBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subFooterCardTxt {
  color: grey;
  font-family: vars.$Regular;
  font-size: 12px;
}

.dashboard-text4 {
  background-color: #ffffff84;
  padding: 5px 15px;
  border: 1px solid rgba(212, 212, 212, 0.383);
  border-radius: 5px;
  font-size: 14px;
  font-family: vars.$Medium;
}
