@use "../../config/variables.scss" as vars;
.Footer {
  padding: 20px 0px;
  border-top: 1px solid rgb(241, 241, 241);
  background-color: rgb(244, 244, 244);
  // position: fixed;
  // bottom: 0;
  // width: 100%;
}

.Footer-box1 {
  font-family: vars.$Medium;
  font-size: 14px;
}

.Footer-box2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  color: rgb(73, 73, 73);
  @media only screen and (max-width: 567px) {
    justify-content: flex-start;
  }
}
