@use "../../../config/variables.scss" as vars;

.subModelTemplates-text1 {
  font-size: 16px;
  color: vars.$primary11;
  font-family: vars.$SemiBold;
}

.subModelTemplates-text2 {
  font-size: 12px;
  color: gray;
}
