@use "../../config/variables.scss" as global;
.LoginScreen-Box {
  min-height: 93vh;
}

.login-main-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 93vh;
}
.loginHeadTxt {
  color: rgb(35 39 47);
  font-family: global.$Bold;
  font-size: 30px;
  margin-bottom: 8px;
  @media only screen and (max-width: 567px) {
    font-size: 28px;
  }
}
.loginHeadQuote {
  font-size: 16px;
  color: global.$primary11;
  font-family: global.$Medium;
  text-align: center;
}

.customInput {
  background-color: global.$primary13;
  border: none;
  border-radius: 20px;
  padding: 10px;
  text-indent: 5px;
  width: 100%;
  font-family: global.$Regular;
}

.custom-Button {
  padding: 20px;
  border-radius: 100px;
  width: 100%;
  background-color: rgb(8 126 164);
  font-family: global.$SemiBold;
  font-size: 17px;
  height: 50px;
}

.form-container {
  width: 100%;
  height: auto;
}
