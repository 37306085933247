@use "../../config/variables.scss" as vars;
.Header {
  height: 8vh;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(241, 241, 241);
  padding: 0px 20px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  @media only screen and (max-width: 567px) {
    padding: 0px 0px;
  }
}

.Header-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  gap: 10px;
  font-size: 20px;
  color: vars.$primary12;
  font-family: vars.$Bold;
  font-weight: 900;
  img {
    width: 40px;
    height: 40px;
  }
  @media only screen and (max-width: 567px) {
    padding-top: 4px;
    font-size: 14px ;
    img {
      width: 30px;
      height: 30px;
    }
  }
}

.Header-Box2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.Header-Box3 {
  border-radius: 100%;
  aspect-ratio: 1;
  font-size: 25px;
  color: vars.$primary11;
  height: 6vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgb(243, 243, 243);
  }
  @media only screen and (max-width: 567px) {
    height: 5vh;
  }
}

.Header-Box4 {
  height: 6vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: vars.$primary11;
  font-size: 14px;
  padding: 10px;
  border-radius: 100px;
  font-family: vars.$SemiBold;
  &:hover {
    background-color: rgb(243, 243, 243);
  }
  @media only screen and (max-width: 567px) {
    display: none;
  }
}
